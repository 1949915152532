import { Icon } from "@iconify/react";
import googleFill from "@iconify/icons-eva/google-fill";
import twitterFill from "@iconify/icons-eva/twitter-fill";
import facebookFill from "@iconify/icons-eva/facebook-fill";
import linkedinFill from "@iconify/icons-eva/linkedin-fill";
// material
import { styled } from "@material-ui/core/styles";
import { Link as RouterLink } from 'react-router-dom';
import {
  Grid,
  Link,
  Divider,
  Container,
  Typography,
  Button,
  Stack,
  Box,
} from "@material-ui/core";
//
import SvgIconStyle from "components/SvgIconStyle";
// ----------------------------------------------------------------------

const SOCIALS = [
  { name: "FaceBook", icon: facebookFill },
  { name: "Google", icon: googleFill },
  { name: "Linkedin", icon: linkedinFill },
  { name: "Twitter", icon: twitterFill },
];

const LINKS = [
  {
    headline: "CONTACT US",
    children: [
      { name: "6595 Rosewell Road Ste G PMG 13625 Atlanta", href: "#" },
      { name: "(404) 828 0870", href: "#" },
    ],
  },
  {
    headline: "INFORMATION",
    children: [
      { name: "About us", href: "#" },
      { name: "Contact us", href: "#" },
      { name: "Terms and conditions", href: "#" },
    ],
  },
  {
    headline: "MY ACCOUNT",
    children: [{ name: "Shopping Cart", href: "#" }],
  },
  {
    headline: "LET US HELP YOU",
    children: [{ name: "Wishlist", href: "#" }],
  },
];

const RootStyle = styled("div")(({ theme }) => ({
  position: "relative",
  background: "#000000",
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 100%",
  color: "#B8B8B8"
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider sx={{ border: "2px solid #ffffff" }} />

      <Container maxWidth="lg" sx={{ py: 5 }}>
        {" "}
        <Grid
          container
          justifyContent={{ xs: "center", md: "space-between" }}
          sx={{ textAlign: "center" }}
        >
          <Grid
            container
            item
            xs={12}
            sm={6}
            md={6}
            justifyContent={{ xs: "center", md: "space-between" }}
          >
            <Stack className="halfw">
              <Link to="/" className={"footerLogo"} component={RouterLink}>
              <img
                src="/images/logo-gary.png"
                style={{ width: "200px", margin: "auto" }}
              />
              </Link>
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            alignSelf={"top"}
            sx={{ marginTop: ["30px", 0, 0, 0], textAlign: "center" }}
          >
            <Stack
              padding="0px 0px 0px 0px"
              direction="column"
              justifyContent="space-between"
              sx={{
                maxWidth: ["100%", "200px", "200px", "200px"],
                textAlign: ["center", "left"],
              }}
            >
              <Typography
                sx={{
                  fontSize: 24,
                  fontWeight: 500,
                  lineHeight: "35px",
                }}
              >
                Links
              </Typography>
              <br />

              <Link href="https://docs.conflictedgarys.com" color="#B8B8B8" className="footer-link" target="_blank">
                DOCS
              </Link>


              <Link href="https://tonicLABS.io/" color="#B8B8B8" className="footer-link" target="_blank">
                tonicLABS.io
              </Link>
              <Link to="/terms" color="#B8B8B8" className="footer-link" component={RouterLink}>
                Terms & Conditions
              </Link>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            alignSelf={"center"}
            sx={{ marginTop: ["30px", 0, 0, 0], textAlign: "center" }}
          >
            <Stack
              padding="0px 0px 0px 0px"
              direction="column"
              justifyContent="space-between"
              sx={{
                maxWidth: ["100%", "200px", "200px", "200px"],
                textAlign: ["center", "left"],
              }}
            >
              <Typography
                sx={{
                  fontSize: 24,
                  fontWeight: 500,
                  lineHeight: "35px",
                }}
              >
                Socials
              </Typography>
              <br />
              <Link href="https://twitter.com/ConflictedGarys" color="#B8B8B8" className="footer-link" target="_blank">
                Twitter
              </Link>
              <Link href="https://discord.gg/ConflictedGarys" color="#B8B8B8" className="footer-link" target="_blank">
                Discord
              </Link>
              <Link
                  href="https://linktr.ee/ConflictedGarys"
                  target="_blank"
                  color="#B8B8B8" className="footer-link"
              >
                Linktr.ee
              </Link>
              <Link href="https://www.instagram.com/conflictedgarys/" color="#B8B8B8" className="footer-link" target="_blank">
                Instagram
              </Link>

            </Stack>
          </Grid>
        </Grid>
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 400,
            lineHeight: "35px",
            textAlign: "center",
            marginTop: "50px",
          }}
        >
          Copyright © 2021-2022 ConflictedGarys all rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
